"use strict";

jQuery(document).ready(function (jQuery) {
  jQuery(document).foundation();
  var nav = jQuery("nav#site-navigation");
  jQuery('button#openMenu').click(function () {
    nav.addClass("open");
  });
  jQuery('button#closeMenu').click(function () {
    nav.removeClass("open");
  });
  if (Cookies.get('popup_once') !== '1') {
    setTimeout(function () {
      jQuery('#newsletter-popup').addClass('slide-up', 1000, 'easeInOut');
      Cookies.set('popup_once', '1');
    }, 5000);
  } else {
    $('#newsletter-popup').hide();
  }
  jQuery('button#closePopup').click(function () {
    Cookies.set('popup_once', '1');
    jQuery('#newsletter-popup').removeClass('slide-up', 1000, 'easeInOut');
  });
  $('.files-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    infinite: false,
    adaptiveHeight: true,
    prevArrow: $('.arrow-files-prev'),
    nextArrow: $('.arrow-files-next'),
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    }]
  });
  $('.column-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    infinite: false,
    adaptiveHeight: true,
    prevArrow: $('.arrow-column-prev'),
    nextArrow: $('.arrow-column-next'),
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    }]
  });
  if ($('#scrollBar').length) {
    var scrollBar = function scrollBar() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = winScroll / height * 100;
      document.getElementById("scrollBar").style.width = scrolled + "%";
    };
    window.onscroll = function () {
      scrollBar();
    };
  }
  jQuery('button#primary-mobile-menu').click(function () {
    nav.removeClass("open");
  });
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }
  jQuery(document).mouseup(function (e) {
    if (!nav.is(e.target) // if the target of the click isn't the container...
    && nav.has(e.target).length === 0)
      // ... nor a descendant of the container
      {
        nav.removeClass('open');
      }
  });
  jQuery('.open-search-from').click(function () {
    jQuery('div').addClass("open-search");
    jQuery('body').addClass("search-main");
  });
  jQuery('#deleteSearch').click(function () {
    jQuery('div').removeClass("open-search");
    jQuery('body').removeClass("search-main");
  });
  $(window).scroll(function () {
    $(".image-inner").css("opacity", 1 - $(window).scrollTop() / 400);
  });
  $(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    if (scroll === 0) {
      jQuery('header').addClass('on-top').removeClass('not-on-top');
    } else {
      jQuery('header').addClass('not-on-top').removeClass('on-top');
    }
  });
  if ($(".parallax-window")[0]) {
    window.addEventListener("scroll", function () {
      var distance = window.scrollY;
      document.querySelector(".parallax-window").style.transform = "translateY(".concat(distance * 0.3, "px)");
    });
  }
  if ($(".accordion-item")[0]) {
    $(".accordion-item").click(function () {
      $(".accordion-item .accordion-title").text("Lees meer berichten");
      $(".accordion-item.is-active").find(".accordion-title").text("Lees minder berichten");
    });
  }
  var forms = document.querySelectorAll('.validate');
  for (var i = 0; i < forms.length; i++) {
    forms[i].setAttribute('novalidate', true);
  }
  var hasError = function hasError(field) {
    if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;
    var validity = field.validity;
    if (validity.valid) return;
    if (validity.valueMissing) return 'Please fill out this field.';
    if (validity.typeMismatch) {
      if (field.type === 'email') return 'Vul een geldige email in.';
    }
    if (validity.patternMismatch) {
      if (field.hasAttribute('title')) return field.getAttribute('title');
      return 'Please match the requested format.';
    }
    if (validity.badInput) return 'Please enter a number.';
    if (validity.stepMismatch) return 'Please select a valid value.';
    return 'The value you entered for this field is invalid.';
  };
  var showError = function showError(field, error) {
    field.classList.add('error');
    var id = field.id || field.name;
    if (!id) return;
    var message = field.form.querySelector('.error-message#error-for-' + id);
    if (!message) {
      message = document.createElement('div');
      message.className = 'error-message';
      message.id = 'error-for-' + id;

      // If the field is a radio button or checkbox, insert error after the label
      var label;
      if (!label) {
        field.parentNode.insertBefore(message, field.nextSibling);
      }
    }
    field.setAttribute('aria-describedby', 'error-for-' + id);
    message.innerHTML = error;
    message.style.display = 'block';
    message.style.visibility = 'visible';
  };
  var removeError = function removeError(field) {
    field.classList.remove('error');
    field.removeAttribute('aria-describedby');
    var id = field.id || field.name;
    if (!id) return;
    var message = field.form.querySelector('.error-message#error-for-' + id + '');
    if (!message) return;
    message.innerHTML = '';
    message.style.display = 'none';
    message.style.visibility = 'hidden';
  };
  document.addEventListener('blur', function (event) {
    if (!event.target.form.classList.contains('validate')) return;
    var error = hasError(event.target);
    if (error) {
      showError(event.target, error);
      return;
    }
    removeError(event.target);
  }, true);
  document.addEventListener('submit', function (event) {
    if (!event.target.classList.contains('validate')) return;
    var fields = event.target.elements;
    var error, hasErrors;
    for (var _i = 0; _i < fields.length; _i++) {
      error = hasError(fields[_i]);
      if (error) {
        showError(fields[_i], error);
        if (!hasErrors) {
          hasErrors = fields[_i];
        }
      }
    }
    if (hasErrors) {
      event.preventDefault();
      hasErrors.focus();
    }
  }, false);
});
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};